<template>
    <div class="month-picker">
        <input class="month-picker__date" :name="`${name}`" :value="value | dateKey"  type="hidden" />
        <label class="month-picker__label h3"><slot>Месяц</slot></label>
        <button class="month-picker__value" @click="showCalendarModal">{{value | dateMonthYearText}}</button>
        <modal class="month-picker__modal"
            classes="month-picker__modal-content"
            :name="name"
            :min-width="300"
            :max-width="400"
            :height="300"
            :adaptive="true"
        >
            <p class="month-picker__title month-picker__title_modal h2"><slot>Месяц</slot></p>
            <button class="month-picker__close" @click="closeCalendarModal">
                <svg-icon class="month-picker__icon month-picker__icon_close" icon="close" />
            </button>
            <div class="month-picker__year">
                <button class="month-picker__change-year" @click="year--">
                    <svg-icon class="month-picker__icon month-picker__icon_chevron" icon="chevron-left" />
                </button>
                <span class="month-picker__year-value">{{year}}</span>
                <button class="month-picker__change-year" @click="year++">
                    <svg-icon class="month-picker__icon month-picker__icon_chevron" icon="chevron-right" />
                </button>
            </div>
            <div class="month-picker__monthes">
                <button class="month-picker__month"
                    v-for="(monthName, monthIndex) in monthes"
                    :key="monthName"
                    :class="{ 'month-picker__month_active': year ===  value.getFullYear() && monthIndex === value.getMonth() }"
                    @click="changeDate(monthIndex)"
                >
                    {{monthName}}
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import { dateKey, dateMonthYearText } from "@/helpers/daterange";
    import isSameDay from "date-fns/isSameDay";

    export default {
        name: "MonthPicker",
        components: {},
        props: {
            name: {
                type: String,
                required: true
            },
            value: {
                type: Date,
                required: true
            }
        },
        data() {
            return {
                year: this.value.getFullYear()
            };
        },
        computed: {
            monthes() {
                return [
                    "Январь", "Февраль", "Март",
                    "Апрель", "Май", "Июнь",
                    "Июль", "Август", "Сентябрь",
                    "Октябрь", "Ноябрь", "Декабрь"
                ]
            }
        },
        methods: {
            showCalendarModal() {
                this.$modal.show(this.name);
            },
            closeCalendarModal() {
                this.$modal.hide(this.name);
            },
            changeDate(monthIndex) {
                let newDate = new Date(this.year, monthIndex, 1);

                if (!isSameDay(this.value, newDate)) {
                    this.$emit("input", newDate);
                }

                this.closeCalendarModal();
            }
        },
        filters: {
            dateKey,
            dateMonthYearText
        }
    }
</script>

<style lang="scss">
    .month-picker {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .month-picker__label {
        margin-right: 5px;
    }
    .month-picker__value {
        color: $red-primary;
        font-weight: inherit;
        border-bottom: 1px dashed;

        &::first-letter {
            text-transform: uppercase;
        }
    }
    .month-picker__modal-content {
        padding: 5px;
    }
    .month-picker__close {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 16px;

        position: absolute;
        top: 0;
        right: 0;
    }
    .month-picker__icon {
        &_close {
            width: 16px;
            height: 16px;
            fill: $red-primary;
        }

        &_chevron {
            width: 24px;
            height: 24px;
            fill: #718096;
        }
    }
    .month-picker__title {
        &_modal {
            text-align: center;
            margin-bottom: 5px;
        }
    }
    .month-picker__year {
        width: 200px;
        margin: 0 auto 5px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }
    .month-picker__year-value {
        color: #2d3748;
        font-size: 18px;
        font-weight: bold;
    }
    .month-picker__change-year {
        width: 25px;
        height: 25px;
        border-radius: 4px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: #edf2f7;
        }
    }
    .month-picker__monthes {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 50px);
    }
    .month-picker__month {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        border-radius: 6px;
        font-size: 18px;

        &:hover {
            color: #fff;
            background-color: rgba($red-secondary, 0.8);
        }

        &_active {
            color: #fff;
            background-color: rgba($red-primary, 0.8);
        }
    }
</style>

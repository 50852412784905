<template>
    <section class="settings-wages">
        <fieldset class="settings-wages__fieldset">
            <month-picker class="settings-wages__datepicker" name="date" v-model="dateModel">
                Месяц:
            </month-picker>
            <select v-if="restaurants.engs.length > 0"
                class="settings-wages__select"
                id="restaurant"
                v-model="restaurantModel"
            >
                <option class="settings-wages__option"
                    v-for="restaurant in restaurants.byEng"
                    :key="restaurant.eng"
                    :value="restaurant.eng"
                >
                    {{restaurant.name}}
                </option>
            </select>
        </fieldset>
        <spinner class="settings-wages__spinner" v-if="status === 'loading'" />
        <error-message class="settings-wages__error-message" v-else-if="status === 'error'">
            Не удалось загрузить настройки.
        </error-message>
        <form v-else class="settings-wages__box box" @submit.prevent="updateWage">
            <div class="settings-wages__form-row"
                v-for="wageFormRow in wageForm"
                :key="`${wageFormRow.serviceType}-${wageFormRow.wageId}`"
            >
                <div class="settings-wages__control">
                    <label class="settings-wages__label h3" :for="`${wageFormRow.serviceType}-${wageFormRow.wageId}-serviceType`">Тип обслуживания</label>
                    <input class="settings-wages__input" type="text" disabled
                        :id="`${wageFormRow.serviceType}-${wageFormRow.wageId}-serviceType`"
                        :value="wageFormRow.serviceType"
                    >
                </div>
                <div class="settings-wages__control">
                    <label class="settings-wages__label h3" :for="`${wageFormRow.serviceType}-${wageFormRow.wageId}-wageGroup`">Группа ФОТ</label>
                    <input class="settings-wages__input" type="text" disabled
                        :id="`${wageFormRow.serviceType}-${wageFormRow.wageId}-wageGroup`"
                        :value="wageFormRow.wageName"
                    >
                </div>
                <div class="settings-wages__control"
                    v-for="(field, fieldName) in wageFormRow.fields"
                    :key="`${wageFormRow.serviceType}-${wageFormRow.wageId}-${fieldName}`"
                >
                    <label class="settings-wages__label h3" :for="`${wageFormRow.serviceType}-${wageFormRow.wageId}-${fieldName}`">{{field.title}}</label>
                    <input class="settings-wages__input"
                        type="text"
                        :id="`${wageFormRow.serviceType}-${wageFormRow.wageId}-${fieldName}`"
                        :class="{ 'settings-wages__input_invalid': field.isValueInvalid }"
                        :value="field.inputValue"
                        @input="handleWageInput(wageFormRow.serviceType, wageFormRow.wageId, fieldName, $event)"
                    >
                    <span class="settings-wages__error" v-if="field.error">{{field.error}}</span>
                </div>
            </div>
            <div class="settings-wages__footer">
                <button class="settings-wages__button settings-wages__button_save" type="submit" :disabled="isSubmitButtonDisabled">
                    Сохранить
                </button>
            </div>
        </form>
    </section>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import { dateKey } from "@/helpers/daterange";
    import MonthPicker from "@/components/MonthPicker";

    export default {
        name: "SettingsWages",
        components: {
            MonthPicker
        },
        data() {
            return {
                status: "loading",
                wageForm: [
                    {
                        serviceType: "Доставка",
                        wageId: 1,
                        wageName: "Курьерская служба",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Доставка",
                        wageId: 2,
                        wageName: "Менеджер доставки",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Доставка",
                        wageId: 3,
                        wageName: "Кухня",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Доставка",
                        wageId: 4,
                        wageName: "Официанты",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Доставка",
                        wageId: 5,
                        wageName: "Управляющие",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Доставка",
                        wageId: 6,
                        wageName: "Бармен",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Доставка",
                        wageId: 7,
                        wageName: "Администратор",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Доставка",
                        wageId: 8,
                        wageName: "Тех персонал",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Ресторан",
                        wageId: 1,
                        wageName: "Курьерская служба",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Ресторан",
                        wageId: 2,
                        wageName: "Менеджер доставки",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Ресторан",
                        wageId: 3,
                        wageName: "Кухня",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Ресторан",
                        wageId: 4,
                        wageName: "Официанты",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Ресторан",
                        wageId: 5,
                        wageName: "Управляющие",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Ресторан",
                        wageId: 6,
                        wageName: "Бармен",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Ресторан",
                        wageId: 7,
                        wageName: "Администратор",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Ресторан",
                        wageId: 8,
                        wageName: "Тех персонал",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "С собой",
                        wageId: 1,
                        wageName: "Курьерская служба",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "С собой",
                        wageId: 2,
                        wageName: "Менеджер доставки",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "С собой",
                        wageId: 3,
                        wageName: "Кухня",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "С собой",
                        wageId: 4,
                        wageName: "Официанты",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "С собой",
                        wageId: 5,
                        wageName: "Управляющие",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "С собой",
                        wageId: 6,
                        wageName: "Бармен",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "С собой",
                        wageId: 7,
                        wageName: "Администратор",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "С собой",
                        wageId: 8,
                        wageName: "Тех персонал",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Самовынос",
                        wageId: 1,
                        wageName: "Курьерская служба",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Самовынос",
                        wageId: 2,
                        wageName: "Менеджер доставки",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Самовынос",
                        wageId: 3,
                        wageName: "Кухня",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Самовынос",
                        wageId: 4,
                        wageName: "Официанты",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Самовынос",
                        wageId: 5,
                        wageName: "Управляющие",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Самовынос",
                        wageId: 6,
                        wageName: "Бармен",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Самовынос",
                        wageId: 7,
                        wageName: "Администратор",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                    {
                        serviceType: "Самовынос",
                        wageId: 8,
                        wageName: "Тех персонал",
                        hasChanged: null,
                        hasInvalidValues: null,
                        fields: {
                            by_order: {
                                title: "За заказ",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_percent: {
                                title: "% от выручки",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            by_hour: {
                                title: "В час",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                            hours: {
                                title: "Норма часов",
                                originalValue: null,
                                inputValue: null,
                                isSameValue: null,
                                isValueInvalid: null,
                                error: null
                            },
                        }
                    },
                ]
            };
        },
        computed: {
            ...mapState({
                restaurants: state => state.directories.restaurants,
                wageGroups: state => state.directories.wageGroups,
                serviceTypes: state => state.directories.serviceTypes,
                data: state => state.settings.wages.data,
                date: state => state.settings.wages.date,
                restaurant: state => state.settings.wages.restaurant,
            }),
            ...mapGetters([
                "areDirectoriesLoaded",
            ]),
            dateModel: {
                get() {
                    return this.date;
                },
                set(value) {
                    this.setWageParameter({ parameter: "date", value });
                }
            },
            restaurantModel: {
                get() {
                    return this.restaurant;
                },
                set(value) {
                    this.setWageParameter({ parameter: "restaurant", value });
                }
            },
            wages() {
                return this.data?.[dateKey(this.date)]?.filter(({ eng }) => eng === this.restaurant);
            },
            isSubmitButtonDisabled() {
                return this.wageForm.some(({ hasInvalidValues }) => hasInvalidValues) || this.wageForm.every(({ hasChanged }) => !hasChanged);
            }
        },
        methods: {
            ...mapMutations([
                "setWageParameter",
            ]),
            ...mapActions([
                "createMessage",
                "getDirectoriesFromDB",
                "getWagesFromDB",
                "updateWagesInDB",
            ]),
            showData() {
                this.fillWageForm();
                this.status = "success";
            },
            showError() {
                this.status = "error";
            },
            loadDirectories() {
                return this.getDirectoriesFromDB().then(() => {
                    this.setWageParameter({ parameter: "restaurant", value: this.restaurants.engs[0] });
                }).catch(this.showError);
            },
            loadWages() {
                this.status = "loading";
                return this.getWagesFromDB(dateKey(this.date)).catch(this.showError);
            },
            fillWageForm() {
                this.wageForm?.forEach(wageFormItem => {
                    let wage = this.wages?.find(({ type, id_group }) => type === wageFormItem.serviceType && id_group === wageFormItem.wageId);

                    if (wage) {
                        wageFormItem.hasChanged = false;
                        wageFormItem.hasInvalidValues = false;

                        Object.keys(wageFormItem.fields).forEach(field => {
                            wageFormItem.fields[field].originalValue = wage[field];
                            wageFormItem.fields[field].inputValue = wage[field] === null ? "" : String(wage[field]);
                            wageFormItem.fields[field].isSameValue = true;
                            wageFormItem.fields[field].isValueInvalid = false;
                            wageFormItem.fields[field].error = null;
                        });
                    }
                });
            },
            handleWageInput(serviceType, wageId, field, { target: { value }}) {
                let wageFormItem = this.wageForm?.find(wageFormItem => wageFormItem.serviceType === serviceType && wageFormItem.wageId === wageId);

                wageFormItem.fields[field].inputValue = value;

                if (wageFormItem.fields[field].inputValue === "") {
                    wageFormItem.fields[field].isSameValue = wageFormItem.fields[field].originalValue === null;
                    wageFormItem.fields[field].isValueInvalid = false;
                    wageFormItem.fields[field].error = null;
                } else {
                    wageFormItem.fields[field].isSameValue = Number(wageFormItem.fields[field].inputValue) === wageFormItem.fields[field].originalValue;
                    wageFormItem.fields[field].isValueInvalid =
                        window.isNaN(wageFormItem.fields[field].inputValue) ||
                        (Number(wageFormItem.fields[field].inputValue) < 0) ||
                        (field === "by_percent" && (Number(wageFormItem.fields[field].inputValue) > 100));
                    wageFormItem.fields[field].error = wageFormItem.fields[field].isValueInvalid ? "Некорректное значение" : "";
                }

                wageFormItem.hasChanged = Object.values(wageFormItem.fields).some(({ isSameValue }) => !isSameValue);
                wageFormItem.hasInvalidValues = Object.values(wageFormItem.fields).some(({ isValueInvalid }) => isValueInvalid);
            },
            updateWage() {
                let updates = this.wageForm.filter(({ hasChanged }) => hasChanged).map(wageFormItem => {
                    let update = {
                        date: dateKey(this.date),
                        eng: this.restaurant,
                        type: wageFormItem.serviceType,
                        id_group: wageFormItem.wageId,
                    };

                    for (const field of Object.keys(wageFormItem.fields)) {
                        if (!wageFormItem.fields[field].isSameValue) {
                            update[field] = wageFormItem.fields[field].inputValue;
                        }
                    }

                    return update;
                });

                this.updateWagesInDB(updates).then(() => {
                    this.createMessage({
                        type: "success",
                        text: "Изменения сохранены"
                    });
                }).catch(() => {
                    this.createMessage({
                        type: "failure",
                        text: "Не удалось сохранить изменения"
                    });
                });
            }
        },
        created() {
            if (this.areDirectoriesLoaded && this.wages) {
                this.showData();
            } else if (!this.areDirectoriesLoaded && this.wages) {
                this.loadDirectories().then(this.showData);
            } else if (this.areDirectoriesLoaded && !this.wages) {
                this.setWageParameter({ parameter: "restaurant", value: this.restaurants.engs[0]});
                this.loadWages().then(this.showData);
            } else {
                this.loadDirectories().then(this.loadWages).then(this.showData);
            }
        },
        watch: {
            wages(wages) {
                if (!wages) {
                    this.loadWages().then(this.showData);
                } else {
                    this.fillWageForm();
                }
            },
        }
    }
</script>

<style lang="scss">
    .settings-wages__fieldset {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: center;

        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .settings-wages__select {
        min-width: 240px;
        height: 40px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;
    }
    .settings-wages__title {
        line-height: 1;
    }
    .settings-wages__box {
        padding: 24px 16px;

        display: grid;
        grid-auto-flow: row;
        grid-gap: 30px;
    }
    .settings-wages__form-row {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 5px;

        @include desktop {
            grid-auto-flow: column;
            grid-gap: 16px;
        }
    }
    .settings-wages__controls {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 15px;
        }
    }
    .settings-wages__control {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 16px;
        position: relative;
    }
    .settings-wages__label {
        margin: 0 0 5px;
    }
    .settings-wages__input {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid $gray-line;
        border-radius: 4px;

        font-size: 16px;
        line-height: 1;

        &[disabled] {
            background-color: $gray-line;
        }

        &_invalid {
            border-color: $red-secondary;
        }
    }
    .settings-wages__error {
        color: $red-secondary;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .settings-wages__footer {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .settings-wages__button {
        padding: 10px;
        border-radius: 6px;

        &[disabled] {
            background-color: $gray-form;
        }

        &_save {
            min-width: 100px;
            color: #fff;
            font-size: 14px;
            background-color: rgba($red-secondary, 0.7);

            @include desktop {
                min-width: 120px;
                font-size: 16px;
            }
        }
    }
</style>
